import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageTitle from "../components/generic/page-title"

import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

const NotFoundPage = () => {
  const data = useStaticQuery(query)
  const stockImage = data.stockImage.childImageSharp.fixed
  return (
    <Layout>
      <SEO
        title="404: Not found"
        description="404 Error. The page you're looking for doesn't exist. Please see our home page."
      />
      <PageTitle title="404 Error: Page Not Found" />
      <div className="page-404-error">
        <p className="paragraph">
          Sorry, but you've reached this page in error. The page you have
          requested cannot be found. Please go back to the home page.
        </p>
        <Img fixed={stockImage} />
      </div>
    </Layout>
  )
}
export default NotFoundPage

const query = graphql`
  query {
    stockImage: file(relativePath: { eq: "oops-404.png" }) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
